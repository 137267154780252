import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import CumulativeAnalytics from "./Pages/CumulativeAnalytics/CumulativeAnalytics";
import AccessType from "./Pages/AccessType/AccessType";
import Publishers from "./Pages/Publishers/Publishers";
import RejectingVsAcceptingJournal from "./Pages/RejectingVsAcceptingJournal/RejectingVsAcceptingJournal";
import TimesToPublication from "./Pages/TimesToPublication/TimesToPublication";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import AcceptingVsSubjectCatagory from "./Pages/AcceptingVsSubjectCatagory/AcceptingVsSubjectCategory";
import InsightsByRejectingJournals from "./Pages/InsightsByRejectingJournals/InsightsByRejectingJournals";
import InsightsByAcceptingJournals from "./Pages/InsightsByAcceptingJournals/InsightsByAcceptingJournals";
import InsightsByCitations from "./Pages/InsightsByCitations/InsightsByCitations";
import InsightsBySubjectCategoryAndRespectiveCitations from "./Pages/InsightBySubjectCategoryAndRespectiveCitations/InsightBySubjectCategoryAndRespectiveCitations";
import InsightsByRejectionAndPublicationTrend from "./Pages/InsightsByRejectionAndPublicationTrend/InsightsByRejectionAndPublicationTrend";
import InsightsByJournalCascade from "./Pages/InsightByJournalCascade/InsightByJournalCascade";
import AdvancedAnalytics from "./Pages/AdvancedAnalytics/AdvancedAnalytics";

const RoutesList = () => {
  return (
    // <Router>
    <Routes>
      <Route path="/cumulative-analytics" element={<CumulativeAnalytics />} />
      <Route
        path="/"
        element={<Navigate replace to="/cumulative-analytics" />}
      />
      <Route path="/insights-by-access-type" element={<AccessType />} />
      <Route path="/insights-by-publishers" element={<Publishers />} />
      <Route
        path="/insights-by-rejecting-journals"
        element={<InsightsByRejectingJournals />}
      />
      <Route
        path="/insights-by-accepting-journals"
        element={<InsightsByAcceptingJournals />}
      />
      <Route
        path="/insights-by-accepting-journals-vs-subject-categories"
        element={<AcceptingVsSubjectCatagory />}
      />
      <Route
        path="/insights-by-rejecting-vs-accepting-journals"
        element={<RejectingVsAcceptingJournal />}
      />
      <Route
        path="/insights-by-subject-category-and-respective-citations"
        element={<InsightsBySubjectCategoryAndRespectiveCitations />}
      />
      <Route
        path="/insights-by-time-to-publication"
        element={<TimesToPublication />}
      />
      <Route
        path="/insights-by-average-citations"
        element={<InsightsByCitations />}
      />
      <Route
        path="/insights-by-journal-rejection-trend"//"/insights-by-rejection-and-Publication-Trend"
        element={<InsightsByRejectionAndPublicationTrend />}
      />
      <Route
        path="/insights-by-journal-cascade"
        element={<InsightsByJournalCascade />}
      />

      <Route
        path="/advanced-analytics"
        element={<AdvancedAnalytics />}
      />

      <Route path="/resetPassword" element={<ForgetPassword />} />
    </Routes>
    // </Router>
  );
};

export default RoutesList;
